import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import * as api from "../../api/api";
import Layout, { Title } from "../../components/Layout/Layout";
import urls from "../../urls.js";

export default function AppListPage(props) {
    const history = useHistory();
    const { organizationId, locationId } = useParams();
    const [apps, setApps] = React.useState([]);
    const [installedApps, setInstalledApps] = React.useState([]);
    const [location, setLocation] = React.useState();

    if (!locationId || locationId === "undefined") {
        history.replace(urls.locations(organizationId));
    }

    // Fetch all available apps
    React.useEffect(() => {
        return api.getCorvusApps(setApps);
    }, []);

    // Fetch installed available apps
    React.useEffect(() => {
        if (location) {
            setInstalledApps(location.apps ? location.apps.map(app => app.id) : []);
        }
    }, [location]);

    React.useEffect(() => {
        return api.subscribeLocation(organizationId, locationId, setLocation);
    }, [organizationId, locationId]);

    function onInstallApp(appToInstall) {
        // TODO : use separate documents for this use case
        // concurrent user updates will cause conflicts
        // e.g. when a user updates, another had already loaded the page, then also saves, boom!
        return api.setLocation(organizationId, locationId, {
            ...location,
            apps: [...location.apps || [], { id: appToInstall.id }],
        });
    }

    function onRemoveApp(appToRemove) {
        return api.setLocation(organizationId, locationId, {
            ...location,
            apps: location.apps.filter((app) => app.id !== appToRemove.id),
        });
    }

    return (
        <Layout>
            {/* <RoutineListBreadcrumbContainer /> */}
            <Card>
                <Title title="Apps" />
            </Card>
            <div>
                <Row>
                    {apps.map(app => (
                        <Col sm={4} className="mt-4">
                            <Card>
                                {/* <Card.Img variant="top" src={app.thumbnail} /> */}
                                <Card.Body>
                                    <Card.Title>
                                        <span>{app.name}</span>
                                    </Card.Title>
                                    <Card.Subtitle className="mb-2 text-muted">
                                        {app.provider.name}
                                    </Card.Subtitle>
                                    <Card.Text>
                                        {app.description}
                                    </Card.Text>
                                    {installedApps.includes(app.id) && (
                                        <Button
                                            variant="outline-danger"
                                            onClick={() => onRemoveApp(app)}
                                            className="me-2"
                                        >
                                            Uninstall
                                        </Button>
                                    )}
                                    {!installedApps.includes(app.id) && (
                                        <Button
                                            variant="success"
                                            onClick={() => onInstallApp(app)}
                                            className="me-2"
                                        >
                                            Install
                                        </Button>
                                    )}
                                </Card.Body>
                            </Card>
                        </Col>
                    ))}
                </Row>
            </div>
        </Layout>
    );
}
